import styled from 'styled-components';
import Colors from './Colors';

const Button = styled.button`
  font-family: 'Roboto', sans-serif;
  font-weight:500;
  width: 100%;
  max-height: 100%;
  //width: 83px;
  height: 40px;
  background-color: ${Colors.VIOLET_PURP};
  border-radius: 200px;
  color: white;
  font-size: 2vh;
  border: none;
  position: relative;
  z-index: 11;

  :disabled {
    background-color: ${Colors.DEMO_GREY};
  }
`;

export default Button;

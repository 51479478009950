import React from 'react';

const WatchEmptyImg = () => (
  <svg
    width="100%"
    height="100%"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 622.15 409.15"
  >
    <defs>
      <clipPath id="a"><rect width="622.15" height="409.15" fill="none" /></clipPath>
      <clipPath id="h"><path d="M178.24,245.29l-.93.8c.34-.15,1.18.43,1.88,1.3l.93-.8a3.29,3.29,0,0,0-1.75-1.33l-.12,0" fill="none" /></clipPath>
      <clipPath id="i"><path d="M195.8,266.34,195,267c.66.82,1,1.6.71,1.81l.93-.8c.25-.21,0-1-.7-1.82Z" fill="none" /></clipPath>
      <clipPath id="j"><path d="M174.69,247.26c-.25.21,0,1,.71,1.81l.93-.8c-.66-.82-1-1.6-.71-1.81Z" fill="none" /></clipPath>
      <clipPath id="k"><path d="M173.85,246.28c-.41.35.08,1.63,1.16,3l.93-.8c-1.07-1.34-1.57-2.62-1.16-3Z" fill="none" /></clipPath>
      <clipPath id="l"><path d="M191.18,268.73c.7.87,1.54,1.45,1.88,1.3l.93-.8c-.33.15-1.18-.43-1.87-1.3Z" fill="none" /></clipPath>
      <clipPath id="n"><path d="M190.79,268.9c1.14,1.42,2.52,2.38,3.07,2.13l.93-.8c-.56.25-1.93-.71-3.07-2.13Z" fill="none" /></clipPath>
    </defs>
    <g clipPath="url(#a)">
      <path d="M247.53,46.48c-23.85,0-48.73,4.71-74.49,15.11C44.81,113.37,44.06,352.71,44.57,385.66,99,382.1,198,379.71,311.07,379.71c103.7,0,195.54,2,252.07,5.08C523.78,260.89,408.56,46.47,247.53,46.48" fill="#94c7e8" />
      <path d="M563.14,384.8q.74,2.33,1.45,4.62H44.66s0-1.31-.08-3.76C16.28,387.51,0,389.67,0,392c0,6.78,139.27,12.28,311.07,12.28s311.07-5.5,311.07-12.28c0-2.69-21.88-5.17-59-7.2" fill="#70c4ff" />
      <path d="M311.07,379.71c-113.06,0-212,2.38-266.5,5.94,0,2.45.08,3.76.08,3.76H564.59q-.7-2.29-1.45-4.62c-56.52-3.08-148.37-5.08-252.07-5.08" fill="#4099e8" />
      <path d="M445.11,104.92a14.7,14.7,0,0,1,26-5.89,12,12,0,0,1,23.69-2.42c-.75-4.52-.38-9.64,2.77-13s9.73-3.3,11.62.87c.87-7.19,5.06-23.25,14.12-25.06,6-1.2,13.35,2.17,18.72,9.59,9.8,13.58,8.94,24.78,11.67,31.48a11.43,11.43,0,1,1,22.25,5.16Z" fill="#d6ebf5" />
      <path d="M52.13,45.69a14.7,14.7,0,0,1,26-5.89,12,12,0,0,1,23.69-2.42c-.75-4.52-.38-9.64,2.78-13s9.73-3.3,11.62.87C117.07,18.1,121.27,2,130.32.24c6-1.2,13.35,2.16,18.72,9.59,9.81,13.58,8.94,24.78,11.67,31.48A11.43,11.43,0,1,1,183,46.48Z" fill="#d6ebf5" />
    </g>
    <rect x="340.33" y="345.91" width="8.57" height="42.58" fill="#a3c4e5" />
    <rect x="314.3" y="341.2" width="8.57" height="42.58" fill="#619ce3" />
    <g clipPath="url(#a)">
      <path d="M46.75,255.49q146.24,3.47,292.54,0l-2-19.26q-144.25,4.47-288.58,0l-2,19.26" fill="#ebab94" />
      <path d="M44.49,277.46q148.51,2.33,297.06,0l-2-19.26q-146.52,3.33-293.1,0l-2,19.26" fill="#ebab94" />
      <path d="M42.23,299.44q150.78,1.18,301.59,0l-2-19.26q-148.79,2.18-297.62,0l-2,19.26" fill="#ebab94" />
      <path d="M40,321.41q153.06,0,306.11,0l-2-19.26q-151.06,1-302.15,0-1,9.63-2,19.26" fill="#ebab94" />
    </g>
    <polygon points="347.49 324.38 38.54 324.38 29.4 333.65 354.9 333.65 347.49 324.38" fill="#e09480" />
    <rect x="35.24" y="343.14" width="9.44" height="46.92" fill="#a3c4e5" />
    <rect x="63.93" y="337.96" width="9.44" height="46.92" fill="#619ce3" />
    <rect x="29.4" y="333.65" width="325.5" height="15.42" fill="#ebab94" />
    <g clipPath="url(#a)">
      <path d="M53.53,246.11a3.89,3.89,0,1,0,3.89-3.89,3.89,3.89,0,0,0-3.89,3.89" fill="#a3c4e5" />
      <path d="M51.87,268.42a3.89,3.89,0,1,0,3.89-3.89,3.89,3.89,0,0,0-3.89,3.89" fill="#a3c4e5" />
      <path d="M49.64,289.35a3.89,3.89,0,1,0,3.89-3.89,3.89,3.89,0,0,0-3.89,3.89" fill="#a3c4e5" />
      <path d="M46.75,311a3.89,3.89,0,1,0,3.89-3.89A3.89,3.89,0,0,0,46.75,311" fill="#a3c4e5" />
      <path d="M64,248.51a3.48,3.48,0,0,0-4.49-.57c-2.44,1.59-6.38,5.16-10.85,13.06-8.84,15.6-7.48,18.21-7.48,18.21l6.71,7.37s13-25.39,16.31-30.33c2.27-3.38,1.62-5.73-.2-7.74" fill="#d44a40" />
      <path d="M115,408.73c.73-4-4.43-3.64-7.32-4.38.77.53,1.7,1.29.44,1.59-1.84.44-5.8-.3-6.92-1.66a8.39,8.39,0,0,0-.68,3.25c0,1.2,14.3,2.22,14.48,1.21" fill="#2e213d" />
      <path d="M108.12,405.94c1.26-.3,1.67-1.15-.21-1.54a14.15,14.15,0,0,1-3.33-1.17c-.78-.39-1-1.67-1-1.67l-1.52-.1s-.69,2.54-.83,2.82c1.12,1.36,5.09,2.11,6.92,1.66" fill="#9c703d" />
      <path d="M152.65,385.44c.12-3.82-4.94-2.91-7.91-3.21.84.41,1.88,1,.68,1.5-1.75.72-5.78.59-7.1-.58a8.36,8.36,0,0,0-.17,3.31c.15,1.19,14.46,0,14.5-1" fill="#2e213d" />
      <path d="M145.42,383.74c1.19-.49,1.48-1.39-.44-1.49a14.15,14.15,0,0,1-3.47-.64c-.83-.26-1.27-1.5-1.27-1.5l-1.52.13s-.3,2.62-.39,2.91c1.32,1.17,5.35,1.3,7.1.58" fill="#9c703d" />
      <path d="M85.06,245.14s-.71,5,5.33,18.89c7.13,16.39,6.57,18.2,11.75,15.79l4-1.8s-6.05-21.53-8.68-26.87c-2.93-6-12.43-6-12.43-6" fill="#de5e4f" />
      <path d="M56.67,301.55c2.67-13.29,9.74-20.86,9.74-20.86l24.43,3.15c-.06.06,3.93,7.47,4.26,7.9,9.83,12.84,35.22,13.66,34.54,29.9-1.33,32-18.66,78.76-24.44,81.79a3,3,0,0,1-2.93-.24c-1.22-.11-1.26-.71-1.21-2.51l5.11-70.78-18.74-2.68c-5.93-.58-16.21-1.77-25.67-4.62-5.64-1.7-7.69-8.13-5.09-21.05" fill="#82bad4" />
      <path d="M56.17,304.33c1.39-13.49,68.34-20.24,73.89-5,11,30.12,12.51,77,11.45,80.35-.51,1.59-2.72,1.86-2.72,1.86-1.17.36-1.6-.39-2.07-1.86l-22-64.59-21.59,7.93c-5.71,1.72-16.48,5.28-26.23,3.61-13.84-2.37-11.69-13.37-10.77-22.33" fill="#99d6db" />
      <path d="M93.14,288.25c-4.43,1.41-22.58,5.58-32.58,1.6-4-8.37-3.16-20-2.67-29.17.14-2.63-3.41-7.17-1.77-9.93,2.36-4,11.81-5.45,16.44-5.56,2.32-.06,4.6-.12,5.73-.16,8-.32,8-.07,11.94,3.55,3.35,3.07,2.91,39.68,2.91,39.68" fill="#d44a40" />
      <path d="M93.14,288.25a83.83,83.83,0,0,1-13,2.67c-.49.06-4.33.23-4.33.23s1.64-11.87,2.24-23.75c.57-11.31-3.7-22.46-3.15-22.49s4.41.06,4.89,0c6.52-.22,6.71.23,10.41,3.61,3.35,3.07,2.91,39.68,2.91,39.68" fill="#de5e4f" />
      <path d="M76.86,239s0,7.26-.6,8c-.23.25-.63-.73-1.1-.59-.85.26-1.89,1.37-2.43.12-.84-1.94,1.17-8,1.17-8Z" fill="#9c703d" />
      <path d="M76.72,244.29a4.6,4.6,0,0,1-4.11-.35,28,28,0,0,1,.81-3.8l3.43.54c0,1-.05,2.41-.13,3.61" fill="#7a4f21" />
      <path d="M71.71,237.13c-.23-.9.44-4,1.09-6.56a3.91,3.91,0,0,1,4.84-2.8c3.28.91,6.85,2.12,7.7,3,1.33,1.36.87,3.61.57,5.29s-1.44,4.56-2.75,5.74a7.83,7.83,0,0,1-5.62,1.28C73,242,72,240.53,71.71,237.13" fill="#9c703d" />
      <path d="M70.47,226.74a3.56,3.56,0,0,1,1.93-1.2c.42-2.65,2-6.2,4.77-6.76a13.6,13.6,0,0,1,7.13,1c2.23.88,4.59,4.86,7,4.89,2,.32,2.83-2.76,3.5-1.45a3.24,3.24,0,0,1,.1,1.57,7.13,7.13,0,0,1-4.28,5.89,6.35,6.35,0,0,1-2.45.47,3.3,3.3,0,0,1,0,1.44,18.74,18.74,0,0,1-2.38,5.67,18.27,18.27,0,0,1-1.21,1.66c-.07.11,1.69-4.9,1.73-5.73.1-2-.12-2.8-1.07-3.41-2.8-.61-6.62-2.75-9.07-1.64-3.62,1.18-3.71,8.93-4.08,9.92-.18.48-.72-2.29-.85-2.85a26.25,26.25,0,0,1-1-5.12c-.13-1.34-.44-3.11.29-4.36" fill="#3b2e5c" />
      <path d="M105.11,318.66s15-7.86,20.05-6.39,4.44,27.31.72,37.07-20.77-30.68-20.77-30.68" fill="#82bad4" />
    </g>
    <polygon points="72.92 289.56 73.23 292.72 89.16 298.67 102.06 297.03 88.72 294.62 72.92 289.56" fill="#4573b5" />
    <polygon points="130.96 290.23 89.36 294.82 72.92 289.56 130.35 284.01 130.96 290.23" fill="#a3c4e5" />
    <polygon points="89.6 264.15 130.96 261.43 134.25 261.92 91.67 264.73 89.6 264.15" fill="#4573b5" />
    <polygon points="131.26 293.32 89.16 298.67 91.67 264.73 134.25 261.92 131.26 293.32" fill="#619ce3" />
    <polygon points="87.53 297.7 89.16 298.67 91.67 264.73 89.6 264.15 87.53 297.7" fill="#4573b5" />
    <g clipPath="url(#a)">
      <path d="M74.17,288.78a.66.66,0,0,1,.32-.72,10.91,10.91,0,0,1,4.22-1.14,4.56,4.56,0,0,1,3.38,1.66c.2.3.65.93.47,1.36a1.61,1.61,0,0,1-1.21.69c-.42,0-1-.53-1.11-.13s-3.24-.13-4-.49a21.48,21.48,0,0,0-2.65-.35c-.32,0,.63-.36.52-.88" fill="#9c703d" />
      <path d="M72.31,287.07l1.73.1a.72.72,0,0,1,.68.78,11.52,11.52,0,0,1-.64,3.19c-.55,1.15-3.27.38-3.27.38Z" fill="#ebe8fc" />
      <path d="M72.68,285.27c.06-.77,0-1.58-.76-1.81-7.75-2.38-19.51-5.22-22.75-7.26-2.06-1.29-10-4.51-8.07,7,.36,2.14,8.1,5.42,11.13,6.47,11.4,3.94,19.28,10.88,20.46-4.45" fill="#d44a40" />
      <path d="M72.61,243.94a10.57,10.57,0,0,0,.84,1.45,2.47,2.47,0,0,0,1.71,1L74.43,250a11.47,11.47,0,0,1-4.07-4.72Z" fill="#ebe8fc" />
      <path d="M75.16,246.41l1.43,3.31A7.4,7.4,0,0,0,78.44,247a3,3,0,0,0-.14-2l-1.58-.72s-.57,2-1.56,2.12" fill="#ebe8fc" />
      <path d="M77.18,218.78a11.25,11.25,0,0,1,3.92.29,14.75,14.75,0,0,1,3.63,1.33,22.6,22.6,0,0,1,2.87,2.65c1,.88,2,2,3.68,2.12h-.07a2.42,2.42,0,0,0,1.74-.4,7.1,7.1,0,0,0,1.17-1,2.56,2.56,0,0,1,.32-.29l-.19-.05.06.13a3.35,3.35,0,0,1,.05,1.15v.07h0a6.92,6.92,0,0,1-2,4.2,6.26,6.26,0,0,1-4.24,1.82h0a.38.38,0,0,0-.38.39v.08a3.44,3.44,0,0,1-.1,1.72c-.14.61-.31,1.21-.52,1.81a18.91,18.91,0,0,1-1.52,3.45h0c-.28.6-.76,1.12-1.11,1.71a14.76,14.76,0,0,0,1.32-1.61h0A17.27,17.27,0,0,0,87.68,235c.24-.6.46-1.21.64-1.83a4.2,4.2,0,0,0,.22-2l-.38.47a6.52,6.52,0,0,0,2.65-.45,7.49,7.49,0,0,0,2.26-1.47,7.94,7.94,0,0,0,2.34-4.77l0,.07a3.63,3.63,0,0,0-.06-1.75,1.69,1.69,0,0,0-.29-.51,1.12,1.12,0,0,0-.35-.25.82.82,0,0,0-.49,0,1.89,1.89,0,0,0-.83.59c-.66.74-1.29,1.32-2,1.2h-.07c-1.06,0-2.13-.94-3.07-1.8a22.33,22.33,0,0,0-3.13-2.58,12.8,12.8,0,0,0-3.91-1.15,10.22,10.22,0,0,0-4,.11" fill="#7866bd" />
      <path d="M319.32,389.71A11.8,11.8,0,0,1,302.63,373l19.91-19.92a11.8,11.8,0,0,1,16.69,16.68Z" fill="#263b66" />
      <path d="M460.32,389.71A11.8,11.8,0,0,0,477,373l-19.92-19.92A11.8,11.8,0,0,0,440.4,369.8Z" fill="#263b66" />
      <path d="M333,165.65a31.34,31.34,0,0,1-5.3-17.26c0-23,27-41.67,60.08-41.67s60.08,18.69,60.08,41.67a31.11,31.11,0,0,1-4.57,16.08l-8.83-5.26a21,21,0,0,0,3.12-10.82c0-17-22.81-31.39-49.81-31.39S338,131.37,338,148.39a21.24,21.24,0,0,0,3.65,11.67Z" fill="#263b66" />
    </g>
    <rect x="438.65" y="162.35" width="37.79" height="48.56" transform="translate(53.39 470.95) rotate(-57.32)" fill="#263b66" />
    <g clipPath="url(#a)"><path d="M429.85,164.79a36.7,36.7,0,1,1,59,41.74Z" fill="#33529c" /></g>
    <rect x="294.11" y="169.26" width="48.56" height="37.79" transform="translate(-51.19 201.72) rotate(-32.68)" fill="#263b66" />
    <g clipPath="url(#a)">
      <path d="M500.58,281.93A111.66,111.66,0,1,1,388.92,170.27,111.66,111.66,0,0,1,500.58,281.93" fill="#33529c" />
      <path d="M481.74,281.93a92.82,92.82,0,1,1-92.82-92.82,92.82,92.82,0,0,1,92.82,92.82" fill="#f5e073" />
      <path d="M392.34,201.15a3.42,3.42,0,1,1-3.42-3.42,3.42,3.42,0,0,1,3.42,3.42" fill="#de5e4f" />
      <path d="M351.49,210.26a3.42,3.42,0,1,1-4.68-1.25,3.42,3.42,0,0,1,4.68,1.25" fill="#de5e4f" />
      <path d="M323,236.24a3.42,3.42,0,1,1-4.68,1.25,3.42,3.42,0,0,1,4.68-1.25" fill="#de5e4f" />
      <path d="M310.48,276.17a3.42,3.42,0,1,1-3.42,3.42,3.42,3.42,0,0,1,3.42-3.42" fill="#de5e4f" />
      <path d="M319.59,317a3.42,3.42,0,1,1-1.25,4.68,3.42,3.42,0,0,1,1.25-4.68" fill="#de5e4f" />
      <path d="M345.56,350.18c.94-1.64,5.38-4.54,7-3.59s-.14,5.38-1.09,7a3.42,3.42,0,1,1-5.93-3.42" fill="#de5e4f" />
      <path d="M387.83,360.37a3.42,3.42,0,1,1,3.42,3.42,3.42,3.42,0,0,1-3.42-3.42" fill="#de5e4f" />
      <path d="M428.68,351.26a3.42,3.42,0,1,1,4.68,1.25,3.42,3.42,0,0,1-4.68-1.25" fill="#de5e4f" />
      <path d="M457.16,325.29a3.42,3.42,0,1,1,4.68-1.25,3.42,3.42,0,0,1-4.68,1.25" fill="#de5e4f" />
      <path d="M469.7,285.36a3.42,3.42,0,1,1,3.42-3.42,3.42,3.42,0,0,1-3.42,3.42" fill="#de5e4f" />
      <path d="M460.59,244.51a3.42,3.42,0,1,1,1.25-4.68,3.43,3.43,0,0,1-1.25,4.68" fill="#de5e4f" />
      <path d="M432.27,213.69A3.42,3.42,0,1,1,431,209a3.42,3.42,0,0,1,1.25,4.68" fill="#de5e4f" />
    </g>
    <rect x="367.64" y="245.24" width="7.14" height="42.74" transform="translate(-38.28 469.58) rotate(-62.05)" fill="#de5e4f" />
    <rect x="384.09" y="260.68" width="64.26" height="5.29" transform="translate(-75.41 233.74) rotate(-28.88)" fill="#de5e4f" />
    <g clipPath="url(#a)">
      <path d="M405.19,281.93a16.27,16.27,0,1,1-16.27-16.27,16.27,16.27,0,0,1,16.27,16.27" fill="#d44a40" />
      <path d="M346.09,166.32a36.7,36.7,0,1,0-59,41.74Z" fill="#33529c" />
      <path d="M522.15,246.92c6.66,1.28,21.81-6.43,21.47-17.66-.46-15.37-17.72-20-20.47-24.79C519.42,198,516.6,191.86,511,199c0,0-4.35-1.86-5.78,4.32-1.35,5.8-6.74,10.85-8.86,14.28-18.85,30.52,9.67,26.21,25.78,29.32" fill="#de5e4f" />
      <path d="M521.08,200.56a17.38,17.38,0,0,0-2.56-3.67,5,5,0,0,0-2-1.28,3.42,3.42,0,0,0-2.4.15,9.54,9.54,0,0,0-3.3,2.7,4.34,4.34,0,0,0-3,.1,4.91,4.91,0,0,0-2.33,2.28,13.66,13.66,0,0,0-1,2.91,15.55,15.55,0,0,1-1.05,2.67c-1.74,3.47-4.35,6.45-6.74,9.68a49.21,49.21,0,0,0-5.47,10.69,21.24,21.24,0,0,0-1.29,5.87,9.77,9.77,0,0,0,1.3,5.78,10,10,0,0,1-.87-5.74A23.73,23.73,0,0,1,492,227a45.81,45.81,0,0,1,2.57-5.31,56.17,56.17,0,0,1,3.07-5c2.34-3.13,5.15-6.09,6.9-9.8A16.68,16.68,0,0,0,505.6,204a12.75,12.75,0,0,1,.91-2.7,3.88,3.88,0,0,1,1.8-1.84,3.56,3.56,0,0,1,2.52-.06h0a.48.48,0,0,0,.53-.16,9.43,9.43,0,0,1,3.07-2.81,2.67,2.67,0,0,1,1.9-.21,4.37,4.37,0,0,1,1.74,1.06,17.91,17.91,0,0,1,2.7,3.41l2.36,3.74c-.67-1.31-1.34-2.62-2.07-3.91" fill="#e89c6e" />
      <path d="M560.54,392.31c-.61-1.15-3.65-2.15-6.07-2.82.73.43,1.45,1.06.33,1.41-1.64.51-5.26.08-6.36-1.08a7.61,7.61,0,0,0-.42,3c0,1.09,13.49,1.38,12.51-.48" fill="#3b2e5c" />
      <path d="M554.81,390.9c1.12-.35.39-1-.33-1.41-1.5-.42-2.46-.7-2.74-.82a3.48,3.48,0,0,1-1.24-1l-.76-.14s-1.17,2-1.29,2.26c1.1,1.16,4.73,1.59,6.37,1.08" fill="#9c703d" />
      <path d="M492.14,395.71c-.42-1.21,4.09-5,5.8-5.73-.52.46-1.26,1.39-.46,1.76,1.16.54,3.47-.29,4.24-1.51a11.63,11.63,0,0,1,.3,3.13c0,1.15-8.9,5.2-9.88,2.35" fill="#3b2e5c" />
      <path d="M496.14,392.78c-.79-.37,1-2.17,1.55-2.63A11.79,11.79,0,0,1,499.6,389a1.77,1.77,0,0,0,.67-1l.88-.12a2.06,2.06,0,0,0,.27,1.63,3,3,0,0,1,.29.72c-.78,1.22-4.43,3.09-5.59,2.56" fill="#9c703d" />
      <path d="M504.7,254.86c0,.36-7.71,11.26-12.06,20.19-4.8,9.87-2,19.16-.42,34.86,1.62,16.55,4.5,63.51,6.61,79,.28,2.09,2.33.54,2.39.12,2-16.14,16.54-102.43,16.54-102.43l31.59,101.56,1.75.1s0-1.82-.35-4.47c-2-17.74-6.64-89.7-9-98.26-4.94-18-9-26.19-9.89-31.09-3.11,1.49-21,3.11-27.17.39" fill="#9c703d" />
      <path d="M551.08,383.94c-.77-19.86-7-89.81-9.33-98.37-4.94-18-9-26.19-9.89-31.09-.73.35-7.86,1.19-12.13,1.76L551.1,388.29s.09-1.69,0-4.36" fill="#7a4f21" />
      <path d="M531.8,225.12c-2.5-3-14.28-5.16-25.52-3.4-5.77.9-13.26,4.42-14.38,8-2.25,7.3,13.12,21.71,12.8,25.09,6.12,2.72,24.05,1.1,27.17-.39a1.63,1.63,0,0,1,0-.8c3.54-11.26,4.49-23.09-.1-28.56" fill="#ba5c94" />
      <path d="M513.11,215s-.8,6.88-.45,7.66,1.66,1.79,2.44.08.06-7.73.06-7.73Z" fill="#9c703d" />
      <path d="M512.64,220a2.38,2.38,0,0,0,2.82.28,37.19,37.19,0,0,0-.14-3.73h-2.38c-.1,1-.22,2.29-.3,3.44" fill="#7a4f21" />
      <path d="M518.89,214.37c.57-1,.38-5.32.38-5.32a8.4,8.4,0,0,1-4.87-2.23c-1.17.18-2.6.15-3.64.78s-1.24,2.3-1.33,3.5a8.62,8.62,0,0,0,.85,4.39,5.5,5.5,0,0,0,3.6,1.87c3.28.08,4.24-.72,5-3" fill="#9c703d" />
      <path d="M541.76,285.57c-4.94-18-9-26.19-9.89-31.09-3.12,1.49-21,3.11-27.17.39,0,.36-7.71,11.26-12.05,20.19-3.29,6.76-3,13.24-2,21.69,2.95.9,14.87,4.39,24.61,4.75,1.5-9,2.49-14.87,2.49-14.87l4.43,14.23c6.54-1.42,14.64-4.84,20.75-7.72a33.32,33.32,0,0,0-1.18-7.57" fill="#b2c9e5" />
      <path d="M521.11,299a13.48,13.48,0,0,0,1,2.47c.41.55,21.18-7.47,21.7-8.06s0-2.94-.9-2.8S521.11,299,521.11,299" fill="#d9e8f2" />
      <path d="M490.35,295.37a15.38,15.38,0,0,0,.33,3.22c.55,1.19,23.89,3.65,24.62,3.37s1.15-2.54.32-2.82-25.28-3.78-25.28-3.78" fill="#d9e8f2" />
      <path d="M500.21,266.68c-8.74,16.37-1.93,19.22,3.34,19.33,5.57.11,6.86-15.78,6.86-15.78Z" fill="#d9e8f2" />
      <path d="M503.79,256.33s9.33,5.85,28.53,0l-.42-2.65s-18.64,3-27.36,0Z" fill="#7a4f21" />
      <path d="M494.34,227.48c-3-1.9-16.91,16-18.57,20.86s-9.5,12.09,1.17,16.58c4.19,1.76,19.47,3.93,24.68,7.47,2.9,2,2.13-1.66,1.95-3-7.07-2.58-12.65-11.86-20.77-13.18,4.23-6.57,9.61-13.67,11.41-21.27.52-2.19,3.15-5.58.14-7.49" fill="#9c703d" />
      <path d="M501.42,269.18a7,7,0,0,1,3.81.68c.83.53,1.39.86,1.39.86s-1.23.53-1.34.92c-.35,1.24,1.75,4,2.53,4.5s-2,1-3.72-.47-3.5-5.19-3.5-5.19Z" fill="#9c703d" />
      <path d="M552.43,241.12c-4.59-2.7-19-17-21.23-15.42s1.33,7.68,2.08,9.62c2.62,6.73,7.41,10.36,14.41,11.34-4.22,2.62-8.07,13.22-11.79,20.16.36,1,.74,2.13,1.14,3.31,5.34-5.37,13.29-13.21,15.41-15.2,6.95-6.55,4.59-11.09,0-13.8" fill="#9c703d" />
      <path d="M529.66,268l6.71-2s5.57,14.18,2.23,16.46c-3.17,2.15-7.59,3.35-8.94-14.41" fill="#d9e8f2" />
      <path d="M559.74,391.64a13.14,13.14,0,1,0,13.14-13.14,13.14,13.14,0,0,0-13.14,13.14" fill="#fff" />
      <path d="M567.63,379.61a13.13,13.13,0,0,0,15.49,20.24,13.14,13.14,0,1,1-15.49-20.24" fill="#aba8b2" />
      <path d="M572.11,388.07l-4.25-3.2s-3.61,1.94-4.12,2.91a16.63,16.63,0,0,0,1,5.54s3.93.45,5.22.43Z" fill="#333" />
      <path d="M578.89,383a16.3,16.3,0,0,1,4.16,1.87s1.8,3,1.11,5a4.66,4.66,0,0,1-3,1.51s-2.26-3.68-3.56-4.42Z" fill="#333" />
      <path d="M578.94,396.91a19.81,19.81,0,0,1-5.55,1l-1.71,4.18a10.4,10.4,0,0,0,4.16,1.57c2,.16,4.44-3.37,4.44-3.37Z" fill="#333" />
      <path d="M566.56,401.91s-2.82-2.31-3.46-4.32c0,0-1.94-.32-2.22-1v1s1.94,4.64,5.26,5.33Z" fill="#333" />
      <path d="M575.08,379.7s.35-.71.21-1c0,0-4.46-1.36-8,1.29,0,0,1.94.18,2.35.65a11.59,11.59,0,0,1,5.45-.95" fill="#333" />
      <path d="M561.16,387.21a5,5,0,0,1,1.11-3.21,7,7,0,0,0-2.52,5.19c0,.43,0,1.73,0,1.73a6.62,6.62,0,0,1,1.41-3.71" fill="#333" />
    </g>
    <g clipPath="url(#h)">
      <path d="M180.12,246.6l-.93.8a6.48,6.48,0,0,0-.43-.49l.93-.8a6.1,6.1,0,0,1,.43.49" fill="#170f00" />
      <path d="M179.69,246.11l-.93.8-.23-.22.93-.8.23.22" fill="#170f00" />
      <polygon points="179.46 245.89 178.53 246.69 178.36 246.54 179.29 245.75 179.46 245.89" fill="#170f00" />
      <polygon points="179.29 245.75 178.36 246.54 178.21 246.43 179.14 245.63 179.29 245.75" fill="#140f00" />
      <polygon points="179.14 245.63 178.21 246.43 178.08 246.34 179.01 245.54 179.14 245.63" fill="#140d00" />
      <polygon points="179.01 245.54 178.08 246.34 177.95 246.26 178.89 245.46 179.01 245.54" fill="#140d00" />
      <polygon points="178.89 245.46 177.95 246.26 177.83 246.19 178.77 245.39 178.89 245.46" fill="#120d00" />
      <polygon points="178.77 245.39 177.83 246.19 177.72 246.13 178.65 245.34 178.77 245.39" fill="#120d00" />
      <polygon points="178.65 245.34 177.72 246.13 177.6 246.09 178.53 245.3 178.65 245.34" fill="#120d00" />
      <polygon points="178.53 245.3 177.6 246.09 177.47 246.07 178.4 245.27 178.53 245.3" fill="#120d00" />
      <path d="M178.4,245.27l-.93.8a.34.34,0,0,0-.16,0l.93-.8a.32.32,0,0,1,.16,0" fill="#120a00" />
    </g>
    <polygon points="195.91 266.24 194.97 267.04 179.19 247.39 180.12 246.59 195.91 266.24" fill="#171200" />
    <g clipPath="url(#i)">
      <path d="M196.61,268.06l-.93.8a.28.28,0,0,0,.09-.21l.93-.8a.28.28,0,0,1-.09.21" fill="#120a00" />
      <polygon points="196.7 267.85 195.76 268.65 195.76 268.52 196.69 267.72 196.7 267.85" fill="#120d00" />
      <polygon points="196.69 267.72 195.76 268.51 195.73 268.39 196.67 267.59 196.69 267.72" fill="#120d00" />
      <polygon points="196.67 267.59 195.73 268.39 195.7 268.26 196.63 267.46 196.67 267.59" fill="#120d00" />
      <polygon points="196.63 267.47 195.7 268.26 195.65 268.13 196.58 267.34 196.63 267.47" fill="#120d00" />
      <polygon points="196.58 267.34 195.65 268.13 195.59 268 196.52 267.2 196.58 267.34" fill="#140d00" />
      <polygon points="196.52 267.2 195.59 268 195.52 267.85 196.45 267.06 196.52 267.2" fill="#140d00" />
      <polygon points="196.45 267.06 195.51 267.85 195.43 267.69 196.36 266.89 196.45 267.06" fill="#140f00" />
      <path d="M196.36,266.89l-.93.8-.12-.19.93-.8.12.19" fill="#170f00" />
      <path d="M196.24,266.7l-.93.8-.18-.26.93-.8.18.26" fill="#170f00" />
      <path d="M196.06,266.44l-.93.8L195,267l.93-.8.15.2" fill="#170f00" />
    </g>
    <polygon points="196.56 268.09 195.62 268.89 195.68 268.86 196.61 268.06 196.56 268.09" fill="#828282" />
    <polygon points="197.36 269.1 196.43 269.89 196.52 269.84 197.45 269.04 197.36 269.1" fill="#120a00" />
    <polygon points="178.24 245.29 177.31 246.09 174.74 247.23 175.68 246.44 178.24 245.29" fill="#0f0a00" />
    <polygon points="175.66 246.44 175.62 246.47 174.69 247.26 174.74 247.23 175.68 246.44 175.66 246.44" fill="#120a00" />
    <g clipPath="url(#j)">
      <path d="M176.33,248.28l-.93.8-.15-.2.93-.8.15.2" fill="#b5b5b5" />
      <path d="M176.18,248.08l-.93.8-.18-.26.93-.8.18.26" fill="#b0b0b0" />
      <path d="M176,247.82l-.93.8-.12-.19.93-.8.12.19" fill="#ababab" />
      <polygon points="175.88 247.63 174.94 248.43 174.85 248.26 175.79 247.47 175.88 247.63" fill="#a6a6a6" />
      <polygon points="175.79 247.47 174.85 248.26 174.78 248.12 175.71 247.32 175.79 247.47" fill="#a1a1a1" />
      <polygon points="175.71 247.32 174.78 248.12 174.72 247.98 175.65 247.19 175.71 247.32" fill="#9c9c9c" />
      <polygon points="175.65 247.19 174.72 247.98 174.67 247.85 175.6 247.06 175.65 247.19" fill="#969696" />
      <polygon points="175.6 247.06 174.67 247.85 174.63 247.73 175.57 246.93 175.6 247.06" fill="#919191" />
      <polygon points="175.57 246.93 174.63 247.73 174.61 247.6 175.54 246.8 175.57 246.93" fill="#8c8c8c" />
      <polygon points="175.54 246.8 174.61 247.6 174.6 247.47 175.54 246.67 175.54 246.8" fill="#878787" />
      <path d="M175.54,246.67l-.93.8a.28.28,0,0,1,.09-.2l.93-.8a.28.28,0,0,0-.09.2" fill="#828282" />
    </g>
    <polygon points="196.56 268.09 195.62 268.89 193.06 270.03 193.99 269.23 196.56 268.09" fill="gray" />
    <polygon points="197.36 269.1 196.43 269.89 193.87 271.03 194.8 270.24 197.36 269.1" fill="#0f0a00" />
    <g clipPath="url(#k)">
      <path d="M175.94,248.46l-.93.8-.25-.33.93-.8.25.33" fill="#170f00" />
      <path d="M175.69,248.13l-.93.8c-.11-.14-.21-.29-.3-.43l.93-.8.3.43" fill="#170f00" />
      <path d="M175.39,247.7l-.93.8-.19-.31.93-.8.2.31" fill="#170f00" />
      <path d="M175.19,247.39l-.93.8-.15-.26.93-.8.15.26" fill="#140f00" />
      <path d="M175,247.12l-.93.8-.12-.24.93-.8.12.24" fill="#140d00" />
      <path d="M174.93,246.88l-.93.8-.1-.22.93-.8.1.22" fill="#140d00" />
      <path d="M174.83,246.66l-.93.8-.08-.21.93-.8.08.21" fill="#120d00" />
      <path d="M174.75,246.45l-.93.8-.06-.21.93-.8c0,.07,0,.13.06.21" fill="#120d00" />
      <path d="M174.69,246.24l-.93.8a1.76,1.76,0,0,1,0-.21l.93-.8a2,2,0,0,0,0,.21" fill="#120d00" />
      <path d="M174.65,246l-.93.8a1.18,1.18,0,0,1,0-.22l.93-.8a1.18,1.18,0,0,0,0,.22" fill="#120d00" />
      <path d="M174.64,245.82l-.93.8a.45.45,0,0,1,.14-.34l.93-.8a.46.46,0,0,0-.14.34" fill="#120a00" />
    </g>
    <polygon points="192.12 267.93 191.18 268.73 175.4 249.08 176.33 248.28 192.12 267.93" fill="#bfbfbf" />
    <g clipPath="url(#l)">
      <polygon points="193.99 269.23 193.06 270.03 192.9 270.05 193.84 269.25 193.99 269.23" fill="#828282" />
      <polygon points="193.84 269.25 192.9 270.05 192.78 270.03 193.71 269.23 193.84 269.25" fill="#878787" />
      <polygon points="193.71 269.23 192.77 270.03 192.66 269.99 193.59 269.19 193.71 269.23" fill="#8c8c8c" />
      <polygon points="193.59 269.19 192.66 269.99 192.54 269.93 193.47 269.13 193.59 269.19" fill="#919191" />
      <polygon points="193.47 269.13 192.54 269.93 192.42 269.86 193.35 269.07 193.47 269.13" fill="#969696" />
      <polygon points="193.35 269.07 192.42 269.87 192.29 269.79 193.23 268.99 193.35 269.07" fill="#9c9c9c" />
      <polygon points="193.23 268.99 192.29 269.79 192.16 269.69 193.09 268.89 193.23 268.99" fill="#a1a1a1" />
      <polygon points="193.09 268.89 192.16 269.69 192.01 269.58 192.95 268.78 193.09 268.89" fill="#a6a6a6" />
      <polygon points="192.95 268.78 192.01 269.58 191.84 269.43 192.78 268.63 192.95 268.78" fill="#ababab" />
      <path d="M192.78,268.63l-.93.8-.23-.22.93-.8.23.22" fill="#b0b0b0" />
      <path d="M192.55,268.42l-.93.8a6.1,6.1,0,0,1-.43-.49l.93-.8a6.32,6.32,0,0,0,.43.49" fill="#b5b5b5" />
    </g>
    <g clipPath="url(#a)">
      <path d="M195.91,266.24,180.12,246.6c-.7-.87-1.54-1.45-1.88-1.3l-2.56,1.14c-.34.15,0,1,.65,1.85l15.79,19.65c.7.87,1.54,1.45,1.88,1.3l2.56-1.14c.34-.15,0-1-.65-1.85m-18.47-22c.55-.25,1.94.71,3.08,2.13l15.79,19.65c1.14,1.42,1.62,2.78,1.07,3l-2.56,1.14c-.55.25-1.93-.71-3.07-2.13l-15.79-19.65c-1.14-1.42-1.62-2.78-1.07-3Z" fill="#1c1200" />
      <path d="M178.24,245.29c.34-.15,1.18.43,1.88,1.3l15.78,19.65c.7.87,1,1.7.65,1.85L194,269.23c-.33.15-1.18-.43-1.87-1.3l-15.79-19.65c-.7-.87-1-1.7-.65-1.85Z" fill="#d9d9d9" />
    </g>
    <polygon points="191.72 268.1 190.79 268.9 175.01 249.25 175.94 248.46 191.72 268.1" fill="#171200" />
    <g clipPath="url(#n)">
      <path d="M194.8,270.24l-.93.8a.52.52,0,0,1-.26,0l.93-.8a.53.53,0,0,0,.26,0" fill="#120a00" />
      <path d="M194.54,270.27l-.93.8-.21,0,.93-.8.21,0" fill="#120d00" />
      <path d="M194.33,270.23l-.93.8-.19-.07.93-.8.2.07" fill="#120d00" />
      <path d="M194.13,270.16l-.93.8-.19-.09.93-.8.19.09" fill="#120d00" />
      <path d="M193.94,270.08l-.93.8-.19-.11.93-.8.19.11" fill="#120d00" />
      <path d="M193.75,270l-.93.8-.2-.13.93-.8.2.13" fill="#140d00" />
      <path d="M193.54,269.84l-.93.8-.22-.15.93-.8.22.15" fill="#140d00" />
      <path d="M193.33,269.68l-.93.8-.24-.19.93-.8.24.19" fill="#140f00" />
      <path d="M193.09,269.5l-.93.8-.28-.24.93-.8.28.24" fill="#170f00" />
      <path d="M192.81,269.26l-.93.8-.37-.36.93-.8.37.35" fill="#170f00" />
      <path d="M192.44,268.9l-.93.8a10.17,10.17,0,0,1-.71-.8l.93-.8a10.37,10.37,0,0,0,.71.8" fill="#170f00" />
    </g>
    <g clipPath="url(#a)">
      <path d="M201.25,255.87a30.48,30.48,0,0,1-5.61-1.7c-1.65-.87-5.32.66-5.94,1a14,14,0,0,0-3.06,2.68s-.67,1,.87.43a10.35,10.35,0,0,0,3.41-2s.28-.36-.05.92,1,1.87,1.25,2c4,1.62,7.19-.51,8.66.27a28.05,28.05,0,0,0,.47-3.59" fill="#9c703d" />
      <path d="M242.48,271.06a34,34,0,0,1-6.42-.65c-13-2.08-38.22-8.52-38.22-8.52l1-6.75,39.69,3.11,2,.15c5.23-10.3,18.71-21.06,20.94-21.25l6.79-.58a16.75,16.75,0,0,1,2.47,2.32c1.92,11.31-22.3,31.81-28.28,32.17" fill="#d64f4f" />
      <path d="M243.66,306.24l.17,3.18.25,5c-2.53,4.56-7.11,8.79-12.72,12.63-6.54-5.07-12.91-12.83-18.19-20.31a211.25,211.25,0,0,1-13.46-21.9c-4.62,20.12-27.16,40-27.16,40l-6.06-3.57c4.67-15.37,18-45.22,27.19-56.38,2.53-3.08,7.37-3,10.42.16Z" fill="#263b66" />
    </g>
    <polygon points="167.58 319.45 165.06 323.78 169.84 326.47 174.3 321.87 167.58 319.45" fill="#9c703d" />
    <g clipPath="url(#a)"><path d="M170.71,327.21l-3.94,5.32L163.4,330l1.52-2.06s-1.19,3.66-7.12-1-6.78-6.39-6.78-6.39-.73-2.24,3,.31,10.05,1.26,10.58,1.66a66.18,66.18,0,0,0,5.92,3.72.59.59,0,0,1,.16.87" fill="#3b2e59" /></g>
    <polygon points="186.4 378.9 189.73 385.83 194.35 382.88 192.4 376.79 186.4 378.9" fill="#9c703d" />
    <g clipPath="url(#a)">
      <path d="M195.41,382.47l2.83,6-3.79,1.79-1.09-2.31s2.67,2.78-4.2,5.83-8.83,3-8.83,3-2.32-.42,1.7-2.52,5.85-8.27,6.45-8.54a66.22,66.22,0,0,0,6.07-3.46.59.59,0,0,1,.85.27" fill="#9c703d" />
      <path d="M251.43,225.28s-.25,4.57-.9,5.44-.47,1.57.33,1.84a15.59,15.59,0,0,0,1.58.09,34.41,34.41,0,0,0,5,.34c1.15-.18,2.74-5.42,2.74-5.42,3.34-1.21,1.34-8.7.37-10.82a1.79,1.79,0,0,0-1.3-1,13.45,13.45,0,0,0-2.47.25,4.79,4.79,0,0,0-3.19,2.18,7.37,7.37,0,0,0-1,2.59l-3,3.88s-.32.67,1.75.61" fill="#ffbf8c" />
      <path d="M249.4,224s-.27,5-1,6-.26,1.46.46,1.52a7.27,7.27,0,0,1,1.65.6h0c1.77.79,6.61,6.69,7.67,8.33l10.68-2.82s-3.73-7.88-3.24-13.77c0,0,1.54-10.36-7.54-10.35a14.82,14.82,0,0,0-2.73.27,5.29,5.29,0,0,0-3.53,2.41,8.16,8.16,0,0,0-1.1,2.85l-3.27,4.28s-.35.74,1.94.67" fill="#9c703d" />
      <path d="M265.09,215.32c-3.52-8.41-21.28-5.85-17,1.5.76,1.29,4,.93,5.76.09a.65.65,0,0,1,.95.56c.07,3.47.25,13.09-.06,13.51s-4,.5-4.83.35-.51-1.61-.57-2.29c0-.32-.55-.54-1.1-.68a6.52,6.52,0,0,1-.36,2.47c-.6,1-.31,1.73.62,1.91a16.65,16.65,0,0,0,1.76-.11h.05a39.94,39.94,0,0,0,5.68-.31c.51-.14,1.15-1.85,1.57-3.09a2.91,2.91,0,0,0-.26-2.39l0,0a5.21,5.21,0,0,1-.63-3.9c.31-1.21,1.07-2.25,2.92-1.6.74.26,1.19,3.45,1.62,4.68,0,.11.07.22.1.34.45,1.66,3,1.72,4.17,1.65a.64.64,0,0,0,.6-.65c0-2.21-.13-10-1-12" fill="#263b66" />
      <path d="M249.85,322s-11.38,6.07-24-17.74c0,0,5.72-17.54,7.94-22.35.73-1.58,1.35-6,2.29-11.45.46-2.72,5.59-17.27,14.24-26.32a24.31,24.31,0,0,1,15.51-7.31l2.48-.21a16.78,16.78,0,0,1,2.47,2.32c6.46,7.15,20.5,31.52-20.92,83.07" fill="#ff6954" />
      <path d="M249.85,322a15.52,15.52,0,0,1-3.54,3.17c-4,2.68-9.5,4.05-15.6,4.57-20.93,1.8-49-6.46-49-6.46,12.12,19.15,12.35,56.86,12.35,56.86h-8.34c-8.46-15.56-21.28-50.87-23.4-67a7.6,7.6,0,0,1,7.37-8.55l56-1.55,2.93-.08,19.78,17.72Z" fill="#263b66" />
      <path d="M268.76,240.83s-13.17-3-27.85,25.94l-44.3-1.67-.7,4.65s39.58,10.83,47,10.38,41.71-30.49,25.82-39.29" fill="#d62e21" />
      <path d="M197.29,265.83s-6.36-3.52-7.73-5.39c0,0-2.63-.17.61,2.88s-4-.24-4-.24,0,1.15,1.43,4.85c.95,2.49,4.69,3,9,.71Z" fill="#9c703d" />
    </g>
  </svg>
);

export default WatchEmptyImg;

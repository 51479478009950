import { useEffect, useState } from 'react';
import useSessionStore from "../Lib/Context";
import { GAMSetup } from '../Components/GAMSetup';
import Spinner from '../Components/Spinner';
import { PreChecks } from '../Lib/Prechecks';
import ReactGA from "react-ga4";


function MiddleWare() {
    const { SessionData, setSessionData } = useSessionStore();
    const DoingPreCheck = PreChecks()
    const [ ErrorState, setErrorState] = useState(false)
    const [ isLoading, setisLoading] = useState(true)
    const [GA4Loaded, setGA4Loaded] = useState(false)

    
    function Initialize_GA4(){
        if(SessionData.google_analytics_tag){
            ReactGA.initialize(
            [
                {
                  trackingId: "G-H1XNVC8QDN",
                },
                {
                  trackingId: SessionData.google_analytics_tag,
                },
              ])
              setGA4Loaded(true)
        }else{
            ReactGA.initialize(
            [
                {
                  trackingId: "G-H1XNVC8QDN",
                },
              ])
              setGA4Loaded(true)
        }
    }

    useEffect(()=>{
        if(DoingPreCheck){
            if(!GA4Loaded){
                Initialize_GA4()
            }else{
                setisLoading(false)
            }
        }
    },[DoingPreCheck,GA4Loaded])

   

if (isLoading) {
    return <Spinner />;
}
if(ErrorState){
    return (
        <div>
          {JSON.stringify(SessionData)}
        </div>
      );
}
else
{
    return (
        <div>
            {/* <LeadForm /> */}
            <GAMSetup />
        </div>
    )
}

  
}

export default MiddleWare;
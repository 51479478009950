import styled from 'styled-components';

const AppContainer = styled.div`
  margin: auto;
  height: 100vh;
  background: white;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 15% 60% 10% 15%;
  grid-template-areas: "Header" "Section" "Navigation" "Footer";

  justify-items: center;

  max-width: 720px;

  @media (orientation: landscape) {
    // max-width: 40%;
    max-width: 60vh;
  } 
`;

const HeaderContainer = styled.div`
  grid-area: Header;
  justify-self: stretch;
`;

const SectionContainer = styled.div`
  grid-area: Section;
  justify-items: center;


  display: flex;
  flex-direction: column;

  // grid-template-columns: 1fr;
  // grid-template-rows: 15% 70% 15%;
  // grid-template-rows: 15% 50% 35%;

`;

const NavigationContainer = styled.div`
  grid-area: Navigation;
`;

const FooterContainer = styled.div`
  grid-area: Footer;
  justify-self: stretch;
`;

const BtnContainer = styled.div`
  margin-top: 1.5vh;
  width: 25vh;
  //height: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1vh;
  justify-content: center;
  display: flex;
  position: relative;
  z-index: 10;
`;

const TextContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  min-height: 8vh;
`;

const Box = styled.div`
background: #fff;
margin: auto;
//width: 30vw;
max-width: 50vh;
height: 25vh;
box-shadow: 0 9px 17px 12px rgba(195,192,192,.5);
padding: 3px 20px 0px 20px;
white-space: pre-wrap;
`;

const AppHeader = styled.div`
  padding-top: 2vh;
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
`;
const CustomTextParagraph = styled.div`
  font-size: 1.8vh;
  margin-top: 1.5vh;
  justify-content: center;
  //height: 4vh;
  display: flex;
  text-align: center;
  min-height: 4vh;
`;
const InfoTextParagraph = styled.div`
  font-size: 1.4vh;
  padding-top: 1.5vh;
//   visibility: ${(props) => (props.step !== 1 ? "hidden" : "visible")};
`;
const CustomTextContainer = styled.div`
margin: 3vh 8vh 3vh;
`;
const LinkButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  font-size: 1.4vh;
  margin-left: 1vh;
`;
const Info = styled.div`
  font-size: 2vh;
  padding-top: 11px;
`;


export {
  AppContainer,
  HeaderContainer,
  SectionContainer,
  NavigationContainer,
  FooterContainer,
  BtnContainer,
  TextContainer,
  Box,
  AppHeader,
  CustomTextParagraph,
  InfoTextParagraph,
  CustomTextContainer,
  LinkButton,
  Info
};

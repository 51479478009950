import { useEffect, useState } from 'react';
import useSessionStore from "../Lib/Context";
import { v6 as uuidv6 } from 'uuid';
import { postRequest, getRequest } from './fetch';
import { defaultPrechecks } from './defaultPrechecks';

export const PreChecks = () =>{
    const [ DoingPreCheck, setDoingPreCheck] = useState(true);
    const [ DoneDoingPrechecks, setDoneDoingPrechecks] = useState(false);
    const [ GetAdInfo, setGetAdInfo] = useState(false);
    //const [ GetLeadFormInfo, setGetLeadFormInfo] = useState(false);
    const { SessionData, setSessionData } = useSessionStore();
    const [ ErrorState, setErrorState] = useState(false)
    

    useEffect(() => {
        if(DoingPreCheck){
            if(SessionData.LSALE || SessionData.lsale){
                let sessID = uuidv6();
                defaultPrechecks.sessionID = sessID
                defaultPrechecks.targeting.lsale = SessionData.lsale
                if(SessionData.url){
                    defaultPrechecks.targeting.url = SessionData.url
                }else{
                    defaultPrechecks.targeting.url = "https://thinkwifi.online"
                }
                if(SessionData.lsale_fb){
                    defaultPrechecks.targeting.lsale_fb = SessionData.lsale_fb
                }else{
                    defaultPrechecks.targeting.lsale_fb = "default"
                }
                if(SessionData.url_fb){
                    defaultPrechecks.targeting.url_fb = SessionData.url_fb
                }else{
                    defaultPrechecks.targeting.url_fb = "https://thinkwifi.online"
                }


                if(SessionData.partner){
                    defaultPrechecks.partner = SessionData.partner 
                }else{
                    defaultPrechecks.partner = "TW"
                }

                if(SessionData.partner_portal_description){
                    defaultPrechecks.partner_portal_description = SessionData.partner_portal_description
                }else{
                    defaultPrechecks.partner_portal_description = "ThinkZone"
                }

                setSessionData(defaultPrechecks)
                setDoneDoingPrechecks(true)

            }else{
                const fetchPrecheckData = async () => {
                    
                    try{
                        let sessID = {"sessionID" : uuidv6()};
                        const precheckData = await postRequest("/prechecks", SessionData);
                        setSessionData({...SessionData, ...precheckData, ...sessID});
                        setDoingPreCheck(false);
                        setGetAdInfo(true);
                    } catch (err){
                        setErrorState(true)
                    } finally {
                    }
                }
                fetchPrecheckData()
                }
        }
    }, [])

    useEffect(() => {
        if(GetAdInfo){
        let urlPath = `/getAd/ageCap/${SessionData.ap_mac_norm}/${SessionData.client_mac_norm}`
        const fetchAdInfo = async () => {
            try{
                const targeting = await getRequest(urlPath);
                let targetingInfo = {}
                let ageCap = {"ageCap": true}
                targetingInfo.targeting = targeting 
                setSessionData({...SessionData, ...ageCap, targeting})
                setDoneDoingPrechecks(true)
            } catch (err) {
                setErrorState(true)
            }finally {
            }
        }
        fetchAdInfo()
    }
   },[GetAdInfo])

   return DoneDoingPrechecks

}
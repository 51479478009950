import { useEffect, useState } from 'react';
import styled from 'styled-components';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import Text from './Text';
import Btn from './Button';
import {
  TextContainer,
  SectionContainer,
  BtnContainer,
} from './ResponsiveLayout';

import PdfViewer from '../Components/PDFReader';


const TextContainerP2 = styled(TextContainer)`
  margin: 8vh;
  flex-direction: column;
`;

const HeaderText = styled(Text.Text)`
  margin-bottom: 1vh;
`;

function TermsPage() {
       
 const [NextPage, setNextPage] = useState(false);
 const [pdfToLoad, setpdfToLoad] = useState('')

    
  const refreshPage = ()=>{
    window.location.reload();
  }

  useEffect(()=>{
    requestPolicy(pdfToLoad)
  },[pdfToLoad])

    function requestPolicy(pdf_file){

      if(pdf_file){
        setNextPage(true)
      }
      
    }
  if (NextPage) {
    console.log(pdfToLoad)
       return <PdfViewer data={pdfToLoad} />;
    }

  return (
    <div className='error-v'>

      <section id="page">
        <header>

        </header>

      <main>
        <SectionContainer >
          <TextContainerP2>
            <HeaderText>
                Welcome to our 
                <br />
                {' '}
                Terms of Service
            </HeaderText>
            <div className='d-flex'>
                <BtnContainer>
                    <Btn onClick={() => setpdfToLoad('./Think-Wifi-Terms-of-Use-June-2021.pdf')}>Terms of Use</Btn>
                </BtnContainer>
                <BtnContainer>
                    <Btn onClick={() => setpdfToLoad('./Think Privacy Notice Feb 2024.pdf')}>Policy</Btn>
                </BtnContainer>
                <BtnContainer>
                    <Btn  onClick={() => setpdfToLoad('./Think PAIA June 2024.pdf')}>PAIA</Btn>
                </BtnContainer>
            </div>

          </TextContainerP2>
        </SectionContainer>
        </main>
        <nav>

              <BtnContainer>
                        <button class="arrow-button" onClick={refreshPage}><span class="arrow"></span>Back to Portal</button>
              </BtnContainer>

        </nav>
        <footer>

        </footer>
      </section>
    </div>
  );
}

export default TermsPage;

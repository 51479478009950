export const defaultPrechecks = {
    "portal_testing": true,
    "apip": "192.168.88.141",
    "client_mac": "1C:3A:60:12:42:E0",
    "dn": "scg.ruckuswireless.com",
    "domain_name": "ThinkWifiZone",
    "mac": "AA:BB:CC:DD:EE:FF",
    "nbiIP": "34.76.255.84",
    "proxy": "0",
    "reason": "Un-Auth-Captive",
    "sip": "scg.ruckuswireless.com",
    "sshTunnelStatus": "1",
    "ssid": "ThinkZone Free Wi-Fi",
    "uip": "192.168.88.25",
    "url": "http://www.msftconnecttest.com/redirect",
    "vlan": "1",
    "wlan": "24",
    "wlanName": "ThinkZone",
    "zoneName": "frhGovTbz-SFOe2pJHtq5KOfDz51wQbYEayE19vLKzc_1678361465124",
    "hostname": "http://localhost",
    "client_mac_norm": "1c3a601242e0",
    "ap_mac": "20:58:69:0B:F4:30",
    "ap_mac_norm": "2058690bf430",
    "vendor": "ruckus",
    "controller_ip": "192.168.0.1",
    "controller_port": "3990",
    "auth_server": "remote",
    "country": "South Africa",
    "administrative_area_level_1": "Western Cape",
    "locality": "Cape Town",
    "sublocality_level_1": "Bellville",
    "ap_name": "TCSS-ZA-BLUEL-BELLV-DHABE-AP-2",
    "product": "RMN",
    "place_name": "DHA BELVILLE",
    "venue_name": "RMN-ZA-BLUEL-BELLV-DHABE",
    "partner": "",
    "partner_name": "Think Digital Media",
    "default_click_through_url": "https://thinkwifi.online",
    "partner_portal_description": "",
    "google_analytics_tag": "G-DJF8C1P4MN",
    "sessionID": "1ef97532-75b0-6150-a71d-5fba39c72085",
    "ageCap": true,
    "targeting": {
        "lsale": "",
        "lsale_fb": "",
        "age_cap_ad": false,
        "url": "thinkwifi.online",
        "url_fb": "thinkwifi.online"
    }
}
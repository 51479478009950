import { logs } from './fetch';

const ApiEndPoint = 'https://apigw.thinkwifizone.com/api/v1';
const BearerToken = 'akljnv13bvi2vfo0b0bw';
  

function auth_openwifi(data){
        let redirectURL = "https://streema.live"
        if (data.auth_domain){
            console.log("session2")
            data.username = (`${data.sessionID}@thinkzone2`)
            redirectURL = data.UserRedirectUrl
            console.log(data.username)
        } else {
            console.log("session1")
            data.username = (`${data.sessionID}@thinkzone`)
            redirectURL = data.UserRedirectUrl
            console.log(data.username)
        }
        let password = 'Rl8zLPZ4w7L7';
        let username = data.username;
        let clickEvent = {
            "sessionID": data.sessionID,
            "clientID": data.clientID,
            "button_id": "go_online",
            "button_url": redirectURL,
            "event": "clickEvent",
            "campaign_code": data.targeting.campaign_code
        }
        var AuthUrl = `http://${data.controller_ip}:${data.controller_port}/logon?username=${username}&password=${password}&userurl=${redirectURL}`
        logs(clickEvent,data)
        setTimeout(() => {
            window.location.href = AuthUrl;
        }, 2000);
        

}

async function auth_ruckus(data){
    if (data.auth_domain){
        console.log("session2")
        data.username = (`${data.sessionID}@thinkzone2`)
        console.log(data.username)
    } else {
        console.log("session1")
        data.username = (`${data.sessionID}@thinkzone`)
        console.log(data.username)
    }
    console.log(data)
    const response = await fetch(`${ApiEndPoint}/auth/thinkzone/ruckus/session`,{
        method: "POST",
        headers: {
          "Authorization" : `Bearer ${BearerToken}`,
          "Content-Type": "application/json",
          "mode": "no-cors"
        },
        body: JSON.stringify(data)});
  
    if (!response.ok) {
        return {"status": "error"}
    }else{
        return {
            "status": "success"
        }
    }
}

function auth_cambium(data){
    var auth_data = JSON.parse(data)
    console.group()
    console.log("pppppppppppppppppppppppppppppppppppp")
    if (auth_data.auth_domain){
        auth_data.ga_user = (`${auth_data.sessionID}@thinkzone2`)
        auth_data.ga_pass = "98adf11facdace11"
        console.log(auth_data.username)
    } else {
        auth_data.ga_user = (`${auth_data.sessionID}@thinkzone`)
        auth_data.ga_pass = "98adf11facdace11"
        console.log(auth_data.username)
    }
    fetch(`${ApiEndPoint}/auth/thinkzone/cambium/session`,
        {
            method: "POST",
            headers: {
                "Authorization" : `Bearer ${BearerToken}`,
                "Content-Type": "application/json",

            },
            body: JSON.stringify(auth_data)

        }
    ).then(
        response => response.json()
    ).then(
        //result => redirect(data,result)
    )
}

export const Authenticate = (data,setdata) => {
    // const { data, setdata } = useSessionStore();
    console.group()
    console.log("===========================")
    console.log("This is the authentication section")
    console.log(data)
    async function Auth_Session(){
        if (data.vendor && !data.portal_testing){
            console.log("vendor = " + data.vendor)
            if(data.vendor === "ruckus"){
                let auth_result = await auth_ruckus(data)
                // let auth_result = {
                //     "details":"success"
                // }
                console.log(auth_result)
                setdata({...data,auth_result})
            } else if (data.vendor === "openwifi") {     
                auth_openwifi(data)
            }else if (data.vendor === "cambium"){
                auth_cambium(data)
            }  else {
                console.log("vendor unknown")
            }
        } else { 
                if (data.portal_testing){
                console.log("lsale_testing")
                let auth_result = {
                    "details":"success"
                }
                setdata({...data,auth_result})
                //redirect(data,result)
            } else {
                console.log("authentication failure")
            }
            
        }
    }
    Auth_Session()
    console.log("===========================")
    console.groupEnd()
}

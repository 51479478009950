import { useEffect, useState, React } from "react";
// import { SessionContext } from "./Lib/Context";
import MiddleWare from "./Pages/MiddleWare";
import useSessionStore from "./Lib/Context";
import queryString from 'query-string';
import Spinner from "./Components/Spinner";
import Error from "./Components/ErrorPage";
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";


// UrlParameters.hostname = `${window.location.protocol}//${window.location.hostname}`;

function App() {
    // const [SessionData, setSessionData] = useState();
    const [isLoading, setisLoading] = useState(true);
    const { SessionData, setSessionData } = useSessionStore();
    const [hasURLParams, sethasURLParams] = useState(false);
    const [ErrorState, setErrorState] = useState(false);
    const UrlParameters = queryString.parse(window.location.search)


    
    useEffect(() => {
      if (Object.keys(UrlParameters).length === 0){
        setErrorState("Prechecks Failed")
      } else {
        getCurrentBrowserFingerPrint().then((fingerprint) => {
          // UrlParameters.clientID = fingerprint.toString();
          SessionData.clientID = fingerprint.toString();
        }).then(() => {
          sethasURLParams(true)
        });
      }
    },[]);

    useEffect(() => {
      if(hasURLParams){
        if(UrlParameters.res === "success"){
            window.open(UrlParameters.userurl,'_blank')
            window.location.href = UrlParameters.userurl
        }else{
        UrlParameters.hostname = `${window.location.protocol}//${window.location.hostname}`;
        setSessionData({...SessionData, ...UrlParameters})
        setisLoading(false)
      }
      }
    },[hasURLParams]
    );

  
    if (ErrorState){
      return (
          <Error errMsg={ErrorState}/>
      );
    }
    if (isLoading) {
      return <Spinner />;
    } else {
      return (
        <MiddleWare />
      )
    }
    
}

export default App;

import { EnableAdUnits, LoadAds, ClearAds,EnableRewardedVideo } from '../Lib/gpt-ad-manager';
import { useEffect, useState } from 'react';
import useSessionStore from "../Lib/Context";
import "../App.css";
import Spinner from "../Components/Spinner";
import Terms from "../Components/Terms&Service";
import Text from '../Components/Text';
import {
    BtnContainer,
    Box,
    AppHeader,
    CustomTextParagraph,
    InfoTextParagraph,
    CustomTextContainer,
    LinkButton,

  } from '../Components/ResponsiveLayout';
import Btn from '../Components/Button';
import VideoErrorPage from '../Components/VideoErrorPage';
import ErrorPage from '../Components/ErrorPage';
import { Authenticate } from '../Lib/Authentication';
import { logs } from '../Lib/fetch';
import ReactGA from "react-ga4";


import WatchVideo2 from './WatchVideo2';
// const googletag = window.googletag || (window.googletag = { cmd: [] });


function WatchVideo (){
    const { SessionData, setSessionData } = useSessionStore();
    const [IsLoading, setIsLoading] = useState(true)
    const [RewardedAd, setRewarded] = useState(false)
    const [isRewardedReady, isRewardGranted, videoErrorState] = EnableRewardedVideo({"adUnit":"video_pri"})
    const [IsOnline,setIsOnline] = useState(false)
    const [PageHeader,setPageHeader] = useState("Page1")
    const [DisplayFooter, setDisplayFooter] = useState(false)
    const [DisplayOnlineFooter, setDisplayOnlineFooter] = useState(false)
    const [IsTOSRequested, setIsTOSRequested] = useState(false)    
    const [NextJourney,setNextJourney] = useState(false)
    const [isAuthenticating, setisAuthenticating] = useState(false)
    const [AuthenticationStatus, setAuthenticationStatus] = useState(false)
    const [ErrorStatus, setErrorStatus] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false);
    const [ClickEventSend1, setClickEventSend1] = useState(true)
    const [Scrolltop, SetScrolltop] = useState(false)
    const Footer = () => (
        <footer className="footer">
            <Box className='example-style'>

                <BtnContainer>
                    <Btn className = "sticky-button" onClick={handleClick}>CONNECT</Btn>
                </BtnContainer>
                
                <CustomTextParagraph>Powered by</CustomTextParagraph>
                <EnableAdUnits Name="powerby" Network="/21967555500/thinkzone/" Targeting={SessionData.partner}/>
                <LoadAds Ads={["powerby"]}/>
                <InfoTextParagraph >

                By connecting, you agree to the Think Wifi 
                <LinkButton className = "sticky-button"  onClick={requestTOS}>
                 Terms of Service
                </LinkButton>
                </InfoTextParagraph>
            </Box>
        </footer>
    );

    const OnlineFooter = () => (
        <footer className="footer">
          <Box className='example-style box-height-1'>
            <div className='d-flex'>
                <BtnContainer className='button-wid'>
                    <Btn className = "sticky-button" onClick={Journey2}>Extend to 60 min</Btn>
                </BtnContainer>
                <BtnContainer className='button-wid'>
                    <Btn disabled={isDisabled} className = "sticky-button" onClick={() => setisAuthenticating(true)}>Go Online Now</Btn>
                </BtnContainer>
            </div>
          </Box>
        </footer>
    );

    const BodyParagraph = () => (
        <div>
             <CustomTextContainer style={{ flex: 'none' }}>
                <Text.Text>
                    <p>Welcome to
                    <br />
                    {' '} 
                    <Text.Text className="bold-text">
                    {SessionData.partner_portal_description}
                    </Text.Text>
                    <br />
                    {' '}
                    fast, free Wi-Fi.
                    </p>
                </Text.Text>
             </CustomTextContainer>
            
        </div>
    )

    const OnlineBodyParagraph = () => (
        <div>
            <CustomTextContainer style={{ flex: 'none' }}>
                 {/* <Text.Paragraph1>
                <span className="bold-text">Almost Online</span>
                </Text.Paragraph1> */}
                <Text.Paragraph1>
                <span ><span className="bold-text">Choose</span> your option below for</span>
                </Text.Paragraph1>
                <br />
                    {' '} 
                <Text.Paragraph1>
                <span className="bold-text" >60 mins </span><span>or</span><span className="bold-text"> 30 mins ?</span>
                </Text.Paragraph1>



            </CustomTextContainer>
        </div>
    )
    
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/watchvideo", title: "Watch Video" });
            setTimeout(() => {
                setIsLoading(false);
            },2000);
    },[])

    useEffect(() => {
        if(!IsLoading){
            setTimeout(() => {
                setDisplayFooter(true);
              }, 2000); 
        }
       
    }, [IsLoading])

    useEffect(() =>{
        if(isRewardGranted){
            setPageHeader("Online Page")
            setIsOnline(true)
            setDisplayFooter(false)
            setTimeout(() => {
                setDisplayOnlineFooter(true)
              }, 2000); 
        }
    },[isRewardGranted]);

    useEffect(() =>{
        if(isAuthenticating){
            setIsLoading(true);
            Authenticate(SessionData,setSessionData);
            setisAuthenticating(false)
        }
    
    }, [isAuthenticating])
    
    useEffect(() => {
        if (SessionData.auth_result){
            console.group("======Sending Click Event======")
            console.log("sending data")
            if(SessionData.auth_result.status !== "error"){
                if(ClickEventSend1){
                    console.log("Click Event is True")
                    console.log("this is journey2") 
                    console.log("clickEvent is true")
                    let clickEvent = {
                        "sessionID": SessionData.sessionID,
                        "clientID": SessionData.clientID,
                        "button_id": "go_online_1",
                        "button_url": SessionData.UserRedirectUrl,
                        "event": "clickEvent",
                        "campaign_code": SessionData.campaign_code
                    }
                    console.log(ClickEventSend1)
                    logs(clickEvent,SessionData)
                    setClickEventSend1(false)
                    setAuthenticationStatus(true)}
                    console.groupEnd("=======Ending console Group for Click Event=========")
            }else if(SessionData.auth_result.status === "error"){
                setErrorStatus(true)
            }
            else{
                setErrorStatus("Authentication Failed")
            }
        }
        
    },[SessionData,ClickEventSend1]);

    useEffect(() => {
        if(AuthenticationStatus){

            window.open(SessionData.UserRedirectUrl,'_blank')
            window.location.href = SessionData.UserRedirectUrl
        }
    },[AuthenticationStatus,SessionData])

    useEffect(() => {
        if(Scrolltop){
            window.scrollTo(0, 0);
        }
      }, [Scrolltop]);

    function handleClick(){
        setRewarded(true)
        SetScrolltop(true)
        isRewardedReady.makeRewardedVisible()
    }
    function requestTOS(){
        setIsTOSRequested(true)
    }
    // function Authentication(){
    //     console.log(SessionData)
    //     //window.open(SessionData.UserRedirectUrl,'_blank')
        
    // }
    function Journey2(){
        SetScrolltop(true)
        setClickEventSend1(false)
        setNextJourney(true)
    }
    

    if(RewardedAd){
        ClearAds(["page1_header","page1_display"])  
    }

   
    if(videoErrorState){
        return(<VideoErrorPage />)
    }
    if (IsTOSRequested){
        return <Terms />
    }
    if(NextJourney){
        return(<WatchVideo2 />)
    }
    if(ErrorStatus){
        return(<ErrorPage errMsg={ErrorStatus}/>)
    }

    return (
        <div>
            <div>
                {IsLoading && <Spinner />}
               
            </div>
            <div>
                {isAuthenticating && <Spinner />}
            </div>
            {/* <div>
                {IsTOSRequested && <Terms />}
            </div> */}
            <div>
                <div>
                    <h1 className='d-none'>{PageHeader}</h1>
                </div>
                <div id="Header">
                <AppHeader>
                    <EnableAdUnits Name="page1_header"  Network="/21967555500/thinkzone/" Targeting={SessionData.AdUnitTargeting.page1_header}/>
                    <EnableAdUnits Name="online_header"  Network="/21967555500/thinkzone/" Targeting={SessionData.AdUnitTargeting.online_header}/>
                    <LoadAds Ads={["page1_header"]}/>
                    {IsOnline && <LoadAds Ads={["online_header"]}/>}
                </AppHeader>
                    
                </div>
                <div>
                    {!IsOnline && <BodyParagraph />}
                    {IsOnline && <OnlineBodyParagraph />}
                </div>
                <div id="Display">
                    <EnableAdUnits Name="page1_display" Network="/21967555500/thinkzone/" Targeting={SessionData.AdUnitTargeting.page1_display}/>
                    <EnableAdUnits Name="online_display" Network="/21967555500/thinkzone/" Targeting={SessionData.AdUnitTargeting.online_display}/>
                    <LoadAds Ads={["page1_display"]}/>
                    {IsOnline && <LoadAds Ads={["online_display"]}/>}
                </div>
                <div>
                    {DisplayFooter && <Footer />}
                    {DisplayOnlineFooter && <OnlineFooter />}
                </div>
          {/* <button onClick={handleClick}>Click me</button>  */}
                
            </div>
            
        </div>
      );
}

export default WatchVideo;
import styled from 'styled-components';
import Colors from './Colors';

const Text = styled.span`
  color: ${Colors.LIGHT_BLUE};
  font-family: 'Roboto', sans-serif;
  font-size: 3.1vh;
  font-style:normal;
  font-weight: 500;
`;

const Heading = styled(Text)`
  color: black;
  font-size: 3vh;
  font-weight: 400;
`;

const Paragraph = styled(Text)`
  color: black;
  font-size: 2vh;
  font-weight: 400;
  line-height: 1.8;
`;
const Paragraph1 = styled(Text)`
    color: black;
    font-family: 'Roboto', sans-serif;
    font-size: 2.5vh !important;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
    margin: 0vh !important;
`;
const Paragraph2 = styled(Text)`
    color: black;
    font-family: 'Roboto', sans-serif;
    font-size: 2.3vh !important;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    margin: 0vh !important;
    padding-top: 11px;
    
`;

export default { Text, Paragraph, Heading, Paragraph1, Paragraph2 };

/* global uiImages */
import React from 'react';

import WatchEmptyImg from '../Images/watchEmptyImg';
import styled from 'styled-components';
import Text from './Text';
import Btn from './Button';

import {
  TextContainer,
  SectionContainer,
  BtnContainer,
} from './ResponsiveLayout';

const TextContainerP2 = styled(TextContainer)`
  margin: 8vh;
  flex-direction: column;
`;

// TODO: potentially can be improved
const Info = styled(Text.Text)`
  font-size: 2vh;
`;

const HeaderText = styled(Text.Text)`
  margin-bottom: 1vh;
`;


const SvgContainer = styled.div`
  // height: 6vh;
`;

// TODO: remove unused props
// eslint-disable-next-line react/prop-types
function VideoErrorPage() {

  const refreshPage = ()=>{
    window.location.reload();
 }

  return (
    <div className='error-v'>
      <section id="page">
        <header>

        </header>

      <main>
        <SectionContainer page={1}>
          <TextContainerP2>
            <HeaderText>
              Advertisement was stopped by user
            </HeaderText>
            <Info>
              This service is available for FREE to you, which is made possible by our advertisers.
            </Info>
            <Info>
              To continue, please support and watch the ad to continue using this service.
            </Info>
          </TextContainerP2>
          <SvgContainer style={{ margin: '0 auto', width: '80%' }}>
            {/* image from the global ui libary */}
                      {
            (typeof (uiImages) !== 'undefined' && uiImages.errorLogo !== undefined)
              ? <img src={uiImages.errorLogo} alt="errorLogo" style={{ margin: 'auto', display: 'block' }} />
              : <WatchEmptyImg />
          }

          </SvgContainer>
        </SectionContainer>
        </main>
        <nav>

              <BtnContainer>
                        <Btn onClick={refreshPage}>CONTINUE</Btn>
              </BtnContainer>

        </nav>
        <footer>

        </footer>
      </section>
    </div>
  );
}

export default VideoErrorPage;

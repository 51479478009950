import { create } from 'zustand';
//import { persist } from 'zustand/middleware';

// const useSessionStore = create(
//   persist(
//     (set) => ({
//       sessionData: null, // Your session data
//       setSessionData: (data) => set({ sessionData: data }),
//       clearSessionData: () => set({ sessionData: null }),
//     }),
//     {
//       name: 'session-storage', // Unique name for the storage
//       getStorage: () => sessionStorage, // Persisting in sessionStorage
//     }
//   )
// );

const useSessionStore = create((set) => ({
    SessionData: {},
    setSessionData: (data) => set((state) => ({ ...state, SessionData: data })),
  }));

export default useSessionStore;




const ApiEndPoint = 'https://apigw.thinkwifizone.com/api/v1';
const LogsApiEndPoint = 'https://logs.thinkwifizone.com';
const BearerToken = 'akljnv13bvi2vfo0b0bw';

export const getRequest = async (urlPath) => {
    let url = ApiEndPoint + urlPath
    const response = await fetch(url,{
        method: "GET",
        headers: {
            "Authorization" : `Bearer ${BearerToken}`,
            "Content-Type": "application/json"
        }
    });
  
    if (!response.ok) {
      throw new Error(`HTTP error: Status ${response.status}`);
    }
  
    return response.json();
  };

export const postRequest = async (urlPath, data) => {
    let url = ApiEndPoint + urlPath
    const response = await fetch(url,{
        method: "POST",
        headers: {
          "Authorization" : `Bearer ${BearerToken}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)});
  
    if (!response.ok) {
      throw new Error(`HTTP error: Status ${response.status}`);
    }
  
    return response.json();
  };

export const logs = async (impressionData, sessionData) => {
    fetch(`${LogsApiEndPoint}/logging/${impressionData.event}/${sessionData.client_mac_norm}/${sessionData.ap_mac_norm}`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(impressionData)

        }
    ).then(
        response => response.json())
    // console.log(impressionData)
}
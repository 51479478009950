import styled from 'styled-components';
import Text from './Text';
//import Btn from './Button';
import {
    TextContainer,
    SectionContainer,
    BtnContainer,
    Info,
  } from './ResponsiveLayout';

  const TextContainerP2 = styled(TextContainer)`
  margin: 8vh;
  flex-direction: column;
`;

function ErrorPage({errMsg}){
  console.log('type of error message')
  console.log(errMsg)

    const refreshPage = ()=>{
        window.location.reload();
      }

    return(
        <div className='error-v'>

      <section id="page">
        <header>

        </header>

      <main>
        <SectionContainer >
          <TextContainerP2>
            <Text.Text>Something went wrong</Text.Text>
            {/* <Text.Paragraph1>PAGE STATUS :</Text.Paragraph1>
            <p id="pageStatus">pageStatus</p> */}
            <Text.Paragraph2 className="bold-text">PAGE STATUS :</Text.Paragraph2>
            <Info>
              [ERROR:
              {errMsg}
              ]
            </Info>
          </TextContainerP2>
        </SectionContainer>
        </main>
        <nav>

              <BtnContainer>
                        <button className="arrow-button" onClick={refreshPage}><span className="arrow"></span>Back to Portal</button>
              </BtnContainer>

        </nav>
        <footer>

        </footer>
      </section>
    </div>
    )

}

export default ErrorPage;
/* global uiImages */
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import styled from 'styled-components';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import TermsPage from './Terms&Service';
import {
  BtnContainer,
} from '../Components/ResponsiveLayout';
import Btn from '../Components/Button';
import CrossIcon from '../Images/crossIcon';
// import pdfjsLib from 'pdfjs-dist/webpack';
pdfjs.GlobalWorkerOptions.workerSrc = "https://unpkg.com/pdfjs-dist@4.4.168/build/pdf.worker.min.mjs";
// Set the workerSrc to the appropriate CDN or local path
// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const ExitCross = styled.button`
  background: none;
  // margin-left: 72vw;
  border: none;
  padding-top: 10px;
`;

const PdfViewer = (pdf_to_load) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [PrePage, setPrePage] = useState(false);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  if (PrePage) {
    return <TermsPage />;
  }

  return (
    <div className='App-body'>

        <ExitCross onClick={() => setPrePage(true)}>
          { (typeof (uiImages) !== 'undefined' && uiImages.tosCloseBtnLogo !== undefined) ? <img alt="" src={uiImages.tosCloseBtnLogo} />
            : <CrossIcon width={20} />}
        </ExitCross>

      <Document
        // file="https://storage.googleapis.com/cdn-think-digital-media/TOS.pdf"
        // file = './Think-Wifi-Terms-of-Use-June-2021.pdf'
        file = {pdf_to_load.data}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      <p>
        Page {pageNumber} of {numPages}
      </p>
      <div className='d-flex'>
      <BtnContainer>
        <Btn disabled={pageNumber <= 1} onClick={() => setPageNumber(pageNumber - 1)}>
          Previous
        </Btn>
      </BtnContainer>

      <BtnContainer>
        <Btn disabled={pageNumber >= numPages} onClick={() => setPageNumber(pageNumber + 1)}>
          Next
        </Btn>
      </BtnContainer>
      </div>

    </div>
  );
};

export default PdfViewer;

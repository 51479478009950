export const adSizes = {
    "page1_display" : [[300, 600], [120, 600], [160, 600], [300, 250], [336, 280]],
    "page1_header" : [[320, 50],[320, 100],[300, 100],[300, 50]],
    "powerby" : [300, 50],
    "online_header" : [[320, 50],[320, 100],[300, 100],[300, 50]],
    "online_display" : [[336, 280],[300, 250],[200, 200],[250, 250]],
    "page2_header" : [[320, 50],[320, 100],[300, 100],[300, 50]],
    "page2_display" : [[300, 600], [120, 600], [160, 600], [300, 250], [336, 280]],
    "online2_header" : [[320, 50],[320, 100],[300, 100],[300, 50]],
    "online2_display" : [[336, 280],[300, 250],[200, 200],[250, 250]],
}